import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from "../firebase";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/Dashboard',
    name: 'dashbodard',
    component: () => import('../components/Dashboard.vue'),
    requireAuth: true
  },
  {
    path: '/Asesores',
    name: 'asesores',
    component: () => import('../components/Asesores.vue'),
    requireAuth: true
  },
  {
    path: '/productos-nuevo',
    name: 'productos-nuevo',
    component: () => import('../components/NuevoProducto.vue'),
    requireAuth: true
  },
  {
    path: '/productos-mis-productos',
    name: 'productos-mis-productos',
    component: () => import('../components/MisProductos.vue'),
    requireAuth: true
  },
  {
    path: '/ProductoEditar',
    name: 'misproductos',
    component: () => import('../components/EditarProducto.vue'),
    requireAuth: true
  },
  {
    path: '/productos-productos-lider',
    name: 'productos-productos-lider',
    component: () => import('../components/ProductosGlobal.vue'),
    requireAuth: true
  },
  {
    path: '/contacto-nuevo',
    name: 'contacto-nuevo',
    component: () => import('../components/nuevoCliente.vue'),
    requireAuth: true
  },
  {
    path: '/contacto-mis-contactos',
    name: 'contacto-mis-contactos',
    component: () => import('../components/misContactos.vue'),
    requireAuth: true
  },
  {
    path: '/contacto-clientes-lider',
    name: 'contacto-clientes-lider',
    component: () => import('../components/ContactosGlobal.vue'),
    requireAuth: true
  },
  {
    path: '/Contacto',
    name: 'misprodudctods',
    component: () => import('../components/editContacto.vue'),
    requireAuth: true
  },
  {
    path: '/Main',
    name: 'main',
    component: () => import('../components/Main.vue'),
    requireAuth: true
  },
  {
    path: '/actividades-nueva',
    name: 'actividades-nueva',
    component: () => import('../components/NuevoActividad.vue'),
    requireAuth: true
  },
  {
    path: '/actividades-mis-actividades',
    name: 'actividades-mis-actividades',
    component: () => import('../components/misActividades.vue'),
    requireAuth: true
  },
  {
    path: '/actividades-lider',
    name: 'actividades-lider',
    component: () => import('../components/ActividadesGlobal.vue'),
    requireAuth: true
  },
  {
    path: '/actividades',
    name: 'verActividad',
    component: () => import('../components/VerActividad.vue'),
    requireAuth: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const ruteProtect = to.matched.some(record => record.meta.requireAuth);
  const user = getAuth().currentUser;

  if (ruteProtect === true && user === null) {
    next({name: 'home'})
  } else {
    next()
  }

})

export default router