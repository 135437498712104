<template>
    <div>
        <v-app-bar flat height="40" color="#fff" style="color:black" app>
          <span style="font-size:10px;">
              <strong>Líder</strong>
            </span>
          <v-spacer></v-spacer>
          <v-badge
          color="#167616"
        dot overlap
      >
          <v-avatar size="30"><img :src="usuario.img_profile" alt=""></v-avatar>
          </v-badge>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="black"
                v-on="on" 
                icon text @click="perfilMenu = !perfilMenu"><i class="far fa-sun"></i></v-btn>
            </template>
            <span>Opciones</span>
            </v-tooltip>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="black"
                v-on="on" 
                icon text @click="nav = !nav"><i class="fas fa-bars"></i></v-btn>
            </template>
            <span>Desplegar Menu</span>
            </v-tooltip>
        </v-app-bar>

        <v-navigation-drawer v-model="perfilMenu" fixed app left width="200">
          <div style="padding:30px 10px 10px 10px;">
            <span v-if="saludo == 1">🌑</span>
            <span v-if="saludo == 2">🌤️</span>
            <span v-if="saludo == 3">☀️</span>
            <span v-if="saludo == 4">🌇</span>
            <span v-if="saludo == 5">🥱</span>
            <span style="font-size:12px">
              Hola, <strong>{{usuario.username}}</strong>.
            </span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
            icon x-small text @click="signOut()" rounded color="red" style="border:solid 1px red;
        text-transform: none;letter-spacing: 0;font-weight: 600;
        ">
        <i class="fas fa-sign-out-alt"></i>
        </v-btn>
            </template>
            <span>Salir</span>
            </v-tooltip>
          </div>

          <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="selectedItem"
            color="#034b03cc"
          >
            <v-list-item
              v-for="(item, i) in itemsPerfil"
              :key="i"
              :to="item.sitio"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </v-navigation-drawer>



        <v-navigation-drawer v-model="nav" fixed app v-if="usuario != null" right>
            <v-img style="margin:20px auto;display:block;" width="150" src="https://app.inmoliderve.com/imagenes/PFyaAwYmfkWpzcP78Ludh9FAU9s2/01691433005.png"></v-img>
            <div style="margin:0 auto;display:block;">


              <v-list dense shaped v-if="usuario.rank == 1">

              
<v-list-item  to="/Main" color="#034b03" v-if="usuario.access == '200'"
active-class="hash"
>
  <v-list-item-title style="font-weight:600">Main</v-list-item-title>
</v-list-item>


<v-list-group color="#034b03"
append-icon="fas fa-sort-down"
    v-for="item in contactoAdmin"
    :key="item.title"
    no-action
    active-class="hash"
>
    <template v-slot:activator>
    <v-list-item-content>
        <v-list-item-title v-text="item.title" style="font-weight:600"></v-list-item-title>
    </v-list-item-content>
    </template>

    <v-list-item
    v-for="child in item.items"
    :key="child.title"
    :to="child.link"
    >
    <v-list-item-content>
        <v-list-item-title v-text="child.title"></v-list-item-title>
    </v-list-item-content>
    </v-list-item>
  </v-list-group>


  <!--<v-list-group
            color="#034b03"
            append-icon="fas fa-sort-down"
            active-class="hash"
                >
                  <template v-slot:activator>
                    <v-list-item-title>Reportes</v-list-item-title>
                  </template>

                  <v-list-group
                    color="#034b03"
                    append-icon="fas fa-sort-down"
                    no-action
                    sub-group
                    v-for="(item, i) in Reportes" :key="i"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{item.title}}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item color="#034b03"
                      v-for="(child, i) in item.items"
                      :key="i"
                      @click="irReporteProducto(child.link, child.is)"
                    >
                      <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
              </v-list-group>-->



</v-list>


<v-list dense shaped v-if="usuario.rank == 2">


<v-list-group color="#034b03"
append-icon="fas fa-sort-down"
active-class="hash"
    v-for="item in contactoAsesor"
    :key="item.title"
    no-action
>
    <template v-slot:activator>
    <v-list-item-content>
        <v-list-item-title v-text="item.title" style="font-weight:600"></v-list-item-title>
    </v-list-item-content>
    </template>

    <v-list-item
    v-for="child in item.items"
    :key="child.title"
    :to="child.link"
    >
    <v-list-item-content>
        <v-list-item-title v-text="child.title"></v-list-item-title>
    </v-list-item-content>
    </v-list-item>
</v-list-group>
<!--<v-list-group
            color="#034b03"
            append-icon="fas fa-sort-down"
                >
                  <template v-slot:activator>
                    <v-list-item-title>Reportes</v-list-item-title>
                  </template>

                  <v-list-group
                    color="#034b03"
                    append-icon="fas fa-sort-down"
                    no-action
                    sub-group
                    v-for="(item, i) in Reportes" :key="i"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{item.title}}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item color="#034b03"
                      v-for="(child, i) in item.items"
                      :key="i"
                      @click="irReporteProducto(child.link, child.is)"
                    >
                      <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
              </v-list-group>-->



</v-list>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<style>
.hash{
  border-left: solid 5px #efc12d;
}</style>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data(){
        return {
          Reportes: [
          {
            items: [
              { is: 1, title: 'Mis Contactos', link: 'https://app.inmoLiderve.com/ReporteMisContactos.php?uid=' },
              { is: 2, title: 'Contactos Lider', link: 'https://app.inmoLiderve.com/ReporteClientesLider.php' },
            ],
            title: 'Contactos',
            },
            {
            items: [
              { is: 1, title: 'Mis Productos', link: 'https://app.inmoLiderve.com/ReporteMisProductos.php?uid=' },
              { is: 2, title: 'Productos Lider', link: 'https://app.inmoLiderve.com/EnviarReporteProductos.php' },
            ],
            title: 'Productos',
            },
            
          ],
          selectedItem:1,saludo:'',
          closss:'Cerrar Sesión',
          itemsPerfil:[
            {icon: 'far fa-user-circle',
            text: 'Inicio',
            sitio: '/'}
            
          ],
            nav:true,
            perfilMenu:true,
            items: [
        {
          items: [
            { title: 'Añadir Contactos', link: '/Contacto/Nuevo' },
            { title: 'Mis Contactos', link: '/Contacto/MisContactos' },
            { title: 'Team Lider', link: '/Contactos/TeamLider' },
          ],
          title: 'Contactos',
        },
        {
          items: [
            { title: 'Añadir Productos', link: '/Productos/Nuevo' },
            { title: 'Mis Productos', link: '/Productos/MisProductos' },
            { title: 'Productos Lider', link: '/Productos/ProductosLider' },
          ],
          title: 'Productos',
        },
        {
          items: [
            { title: 'Añadir Actividades', link: '/actividades-nueva' },
            { title: 'Mis Actividades', link: '/actividades-mis-actividades' },
          ],
          title: 'Actividades',
        }
      ],      
      contactoAdmin: [
        {
          items: [
            { title: 'Añadir Contactos', link: '/contacto-nuevo' },
            { title: 'Mis Contactos', link: '/contacto-mis-contactos' },
            { title: 'Clientes Lider', link: '/contacto-clientes-lider' },
          ],
          title: 'Contactos',
        },
        {
          items: [
            { title: 'Añadir Productos', link: '/productos-nuevo' },
            { title: 'Mis Productos', link: '/productos-mis-productos' },
            { title: 'Productos Lider', link: '/productos-productos-lider' },
          ],
          title: 'Productos',
        },
        {
          items: [
            { title: 'Añadir Actividades', link: '/actividades-nueva' },
            { title: 'Mis Actividades', link: '/actividades-mis-actividades' },
            { title: 'Actividades Lider', link: '/actividades-lider' },
          ],
          title: 'Actividades',
        }       
      ],
      contactoAsesor: [
        {
          items: [
            { title: 'Añadir Contactos', link: '/contacto-nuevo' },
            { title: 'Mis Contactos', link: '/contacto-mis-contactos' },
          ],
          title: 'Contactos',
        },
        {
          items: [
            { title: 'Añadir Productos', link: '/productos-nuevo' },
            { title: 'Mis Productos', link: '/productos-mis-productos' },
          ],
          title: 'Productos',
        },
       {
          items: [
            { title: 'Añadir Actividades', link: '/actividades-nueva' },
            { title: 'Mis Actividades', link: '/actividades-mis-actividades' },
          ],
          title: 'Actividades',
        }
      ],
        }
    },
    methods:{
      slaudos(){
        const fecha = new Date(); 
        const hora = fecha.getHours();
        if(hora >= 0 && hora < 6){
          this.saludo = 1;
        }
        if(hora >= 6 && hora < 8){
          this.saludo = 2;
        }
        if(hora >= 8 && hora < 17){
          this.saludo = 3;
        }
        if(hora >= 17 && hora < 19){
          this.saludo = 4;
        }
        if(hora >= 19 && hora < 24){
          this.saludo = 5;
        }
        return this.saludo
      },
        ...mapActions(['signOut']),
        irReporteProducto(a, e){
          if (e == 1) {
            window.open(a+this.usuario.uid, "_blank");
          }else if (e == 2 && this.usuario.rank == 1) {
            window.open(a, "_blank");
          }
        }
    },
    computed:{
        ...mapState(['usuario'])
    },
    mounted(){
      this.slaudos()
    }
}
</script>