import { initializeApp } from "firebase/app";
import { updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDYMy9C_ak-p4_hCDYYV4nhDcl7btuYb7Y",
  authDomain: "inmoliderve.firebaseapp.com",
  projectId: "inmoliderve",
  storageBucket: "inmoliderve.appspot.com",
  messagingSenderId: "717951985217",
  appId: "1:717951985217:web:c325b063b594a84a9308c0",
  measurementId: "G-D1VBNT0851"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export { app, updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged }